export interface Resource {
  name: string
  raw: number
  refined: number
  priority?: string
  yieldValue?: number;
}

const colors = [
  {
    priority: '1',
    minValue: 10,
  },
  {
    priority: '2',
    minValue: 6,
  },
  {
    priority: '3',
    minValue: 3,
  },
  {
    priority: '4',
    minValue: 0,
  },
]

const resources: Resource[] = [
  {
    name: 'Quantanium',
    raw: 44.04,
    refined: 88.09,
  },
  {
    name: 'Bexalite',
    raw: 20.24,
    refined: 40.48,
  },
  {
    name: 'Borase',
    raw: 16.22,
    refined: 32.45,
  },
  {
    name: 'Taranite',
    raw: 16.22,
    refined: 32.44,
  },
  {
    name: 'Laranite',
    raw: 15.12,
    refined: 30.42,
  },
  {
    name: 'Agricium',
    raw: 13.44,
    refined: 26.88,
  },
  {
    name: 'Hepheastanite',
    raw: 7.33,
    refined: 14.66,
  },
  {
    name: 'Titanium',
    raw: 4.33,
    refined: 8.66,
  },
  {
    name: 'Diamond',
    raw: 3.59,
    refined: 7.18,
  },
  {
    name: 'Gold',
    raw: 3.16,
    refined: 6.32,
  },
  {
    name: 'Copper',
    raw: 2.85,
    refined: 5.7,
  },
  {
    name: 'Beryl',
    raw: 2.15,
    refined: 4.3,
  },
  {
    name: 'Tungsten',
    raw: 2.0,
    refined: 4.03,
  },
  {
    name: 'Corundum',
    raw: 1.33,
    refined: 2.66,
  },
  {
    name: 'Quartz',
    raw: 0.75,
    refined: 1.5,
  },
  {
    name: 'Aluminium',
    raw: 0.64,
    refined: 1.28,
  },
]

export const getResources = () => {
 var a = resources.map((r: Resource) => {
    let prio = "";
    for (var c of colors) {        
      if (r.raw >= c.minValue) {
        prio = c.priority;
        break;
      }
    }
    return {...r, priority: prio}
  });
  console.log(a);
  return a;
}
