import React, { ChangeEvent } from 'react'
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux'
import { getResources, Resource } from './miningData'

export class MiningResource extends React.Component<
  { resource: Resource; setTotal: any },
  Resource
> {
  constructor(props: { resource: Resource; setTotal: any }) {
    super(props)
    this.state = props.resource

    this.handleChange = this.handleChange.bind(this)
  }

  async handleChange(event: ChangeEvent<HTMLInputElement>) {
    let yieldValue = parseInt(event.target.value)
    await this.setState({
      yieldValue: isNaN(yieldValue) ? undefined : yieldValue,
    })
    await this.props.setTotal(this.state)
  }

  round(value: number) {
    return Math.round(value * 100) / 100
  }

  render() {
    return (
      <tr className={'mining-priority-' + this.state.priority}>
        <td>{this.state.name}</td>
        <td>{this.state.raw}</td>
        <td>{this.state.refined}</td>
        <td>{this.round(this.state.raw * 100)}</td>
        <td>{this.round(this.state.refined * 100)}</td>
        <td className="mining-yield mining-input">
          <input value={this.state.yieldValue} onChange={this.handleChange} />
        </td>
        <td className="mining-profit-calculated">
          {this.round((this.state.yieldValue ?? 0) * this.state.refined)}
        </td>
      </tr>
    )
  }
}

export class MiningResources extends React.Component<
  any,
  { resources: Resource[]; totalRefinigCost?: number; total?: number }
> {
  constructor(props: any) {
    super(props)
    this.state = { resources: getResources() }

    this.handleChange = this.handleChange.bind(this)
    this.setTotal = this.setTotal.bind(this)
  }

  async handleChange(event: ChangeEvent<HTMLInputElement>) {
    let totalRefinigCost = parseInt(event.target.value)
    await this.setState({
      totalRefinigCost: isNaN(totalRefinigCost) ? undefined : totalRefinigCost,
    })
  }

  async setTotal(resource: Resource) {
    let found = this.state.resources.find((i) => i.name == resource.name)
    if (!found) {
      return
    }
    found.yieldValue = resource.yieldValue
    let total = this.state.resources
      .map((r) => (r.yieldValue ?? 0) * r.refined)
      .reduce((p, c) => p + c)

    total = Math.round(total * 100)/100;

    await this.setState({
      total,
    })
  }

  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Resource</th>
              <th>Price per unit (cSCU/RAW)</th>
              <th>Price per unit (cSCU/REFINED)</th>
              <th>Price per SCU (RAW)</th>
              <th>Price per SCU (REFINED)</th>
              <th>Yield (cSCU)</th>
              <th>Profit (UEC)</th>
            </tr>
          </thead>
          <tbody>
            {this.state.resources.map((r) => (
              <MiningResource
                resource={r}
                key={r.name}
                setTotal={this.setTotal.bind(this)}
              />
            ))}
          </tbody>
          <tfoot>
            <tr className="mining-total-refining-cost">
              <td colSpan={3}>Total refining costs:</td>
              <td colSpan={2}>&nbsp;</td>              
              <td className="mining-input">
                <input
                  value={this.state.totalRefinigCost}
                  onChange={this.handleChange}
                />
              </td>
              <td>
                {this.state.totalRefinigCost ? '-' : ''}
                {this.state.totalRefinigCost ?? 0}
              </td>
            </tr>
            <tr className="mining-total-profit">
              <td colSpan={3}>Total profit on refinement: </td>
              <td colSpan={2}>&nbsp;</td>            
              <td>&nbsp;</td>
              <td>
                {(this.state.total ?? 0) - (this.state.totalRefinigCost ?? 0)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }
}
