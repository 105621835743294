import React from 'react'
import logo from './logo.svg'
import './App.css'
import { MiningResources } from './features/mining/MiningResources'
import { Route, Router, Routes } from 'react-router-dom'

function App() {
  return (
    <div className='App-body'>
      <Routes>
      <Route path="/mining" element={<MiningResources />} />
    </Routes>
    </div>
  )
}

export default App
